import React, { useState } from "react"
import Config from "../config"

const Contact = ({ data }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState("none")
  const [error, setError] = useState("none")

  const postContact = async e => {
    e.preventDefault()

    const response = await fetch(
      `${Config.apiUrl}/items/contact?access_token=${Config.token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          mail: email,
          subject: subject,
          message: message,
        }),
      }
    )

    if (response.ok) {
      setSuccess("block")
    } else {
      setError("block")
    }

    setName("")
    setEmail("")
    setSubject("")
    setMessage("")
  }

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Adresse:</h4>
                  <p>
                    {data.address}, {data.zip} {data.city}
                  </p>
                </div>

                <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Horaire:</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.hours }}></div>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>{data.mail}</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Téléphone:</h4>
                  <p>{data.phone}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form onSubmit={postContact} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Votre nom"
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Votre email"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Sujet"
                    required
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="8"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" style={{ display: error }}>
                    Une erreur s'est produite, désolé pour le désagrément.
                  </div>
                  <div className="sent-message" style={{ display: success }}>
                    Merci pour votre message, nous reviendrons vers vous
                    rapidement.
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Envoyer</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
