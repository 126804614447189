import React, { useEffect, useState } from "react"
import Config from "../config"

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Contact from "../components/contact"
import Footer from "../components/footer"

const ContactTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [data, setData] = useState({})

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/pages/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  useEffect(() => {
    getData(node.id)
  }, [node])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        {data.banner_display && (
          <Header
            image={data.banner?.data.full_url}
            title={data.title}
            subtitle={data.subtitle}
          />
        )}

        <main id="main">
          <Contact data={data} />
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default ContactTemplate
